.section {
    &-expertise {
        @media (min-width: 1200px) {
            padding: 135px 0 115px;
        }
    }

    &-map {
        .container {
            max-width: 1920px;
            @include margin-auto;
            padding: 0;
        }
    }

    &-story {
        z-index: 1;

        @media (min-width: 1200px) {
            padding: 230px 0;
        }
    }

    &-articles {
        @media (min-width: 1200px) {
            padding: 154px 0 144px;
        }
    }

    &-ctanewsletter {
        @media (min-width: 1200px) {
            padding: 150px 0;
        }

        .section-bg::after {
            display: none;
        }
    }

    @media (max-width: 991px) {}
}

.notice {
    p {
        font-size: 32px;
        line-height: 50px;
        font-weight: 300;

        @media (max-width: 767px) {
            font-size: 24px;
            line-height: 36px;
        }

        @media (max-width: 767px) {
            font-size: 18px;
            line-height: 28px;
        }
    }
}

.expertise {
    .section-head {
        @media (min-width: 1400px) {
            margin-bottom: 155px;
        }
    }

    &-slider {

        @media (min-width: 1201px) {
            margin: 0 -47px;
            padding-bottom: 120px;
            min-height: 867px;
        }

        @media (max-width: 1200px) {
            margin: 0 -20px;
        }

        .item {
            transition: all .3s ease-out;

            @media (min-width: 1201px) {
                padding: 0 47px;
                margin-top: 105px;

                &.slick-current+* {
                    margin-top: 0;
                }
            }

            @media (max-width: 1200px) {
                padding: 0 20px;
            }
        }

        .slick {
            &-dots {
                li {
                    width: 10px;
                    height: 10px;
                    text-align: center;
                    margin: 0 8px;

                    button {
                        width: 100%;
                        height: 100%;
                        padding: 0;
                        border: 2px solid $color-black;
                        background-color: transparent;
                        transition: all .3s ease-out;
                        border-radius: 50%;

                        &::before,
                        &::after {
                            display: none;
                        }
                    }

                    &.slick-active {
                        width: 16px;
                        height: 16px;

                        button {
                            background-color: $color-black;
                        }
                    }
                }
            }
        }
    }

    &-card {
        background-color: #fff;

        &-img {
            position: relative;
            height: 407px;
            text-align: center;
            overflow: hidden;
            transition: all .3s ease-out;
            @include unselect;

            @media (max-width: 767px) {
                height: 280px;
            }

            img {
                @include imgfit;
            }

            &-overlay {
                z-index: 1;
                @include absoldefault;
                mix-blend-mode: multiply;
            }
        }

        &-title {
            z-index: 2;
            @include absolcenter;
            font-size: 150px;
            color: transparent;
            -webkit-text-stroke: 2px #fff;
            transition: all .3s ease-out;
            letter-spacing: -30px;

            @media (max-width: 1550px) {
                font-size: 100px;
                letter-spacing: -18px;
            }

            @media (max-width: 1200px) {
                font-size: 85px;
                letter-spacing: -18px;
            }
        }

        &-cont {
            padding: 32px 20px;
            border: 1px solid #9F9F9F;
            border-top: 0;
            text-align: center;
            transition: all .3s ease-out;

            p {
                font-size: 22px;
                font-weight: 500;
                line-height: 30px;
                transition: all .3s ease-out;

                @media (max-width: 1200px) {
                    font-size: 18px;
                    line-height: 28px;
                }
            }
        }
    }

    @media (min-width: 1201px) {
        .slick-current+* &-card-img {
            height: 536px;
        }

        .slick-current+* &-card-title {
            // font-size: 200px;
        }

        .slick-current+* &-card-cont {
            padding: 55px 20px;
        }

        .slick-current+* &-card-cont p {
            font-size: 30px;
        }
    }
}

.story {
    &-shapes {
        @include unselect;
    }

    &-shape {

        &-1,
        &-2 {
            z-index: -1;
            position: absolute;
        }

        &-1 {
            top: -6%;
            right: -31%;
            width: 42%;
        }

        &-2 {
            bottom: -14%;
            right: -2%;
            width: 31.35%;
        }
    }

    @media (min-width: 1201px) and (max-width: 1550px) {
        .col-xl-6 {
            &:first-child {
                flex: 0 0 65%;
                max-width: 65%;
            }

            &:last-child {
                flex: 0 0 35%;
                max-width: 35%;
            }
        }
    }

    &-img {
        position: relative;

        @media (max-width: 1200px) {
            margin-bottom: 70px;
        }

        &-shape {
            z-index: 2;
            position: absolute;
            left: -5.5%;
            bottom: -13%;
            width: 45%;

            img {
                width: 100%;
            }
        }
    }

    &-mask-img {
        // mask-image: url('../images/about-mask.png');
        mask-size: 100% 100%;
        mask-position: center;
        mask-repeat: no-repeat;
        overflow: hidden;

        &::after {
            content: "";
            z-index: 1;
            @include absoldefault;
            background-image: url('../images/about-mask-outer.png');
            background-size: 100% 100%;
            background-position: center;
            background-repeat: no-repeat;
        }

        @media (min-width: 992px) {
            height: 689px;
        }

        @media (max-width: 767px) {
            height: 400px;
        }

        @media (max-width: 567px) {
            height: 300px;
        }

        img {
            @include imgfit;
            transition: all .3s ease-out;
        }

        &:hover img {
            transform: scale(1.15);
        }
    }

    .section-head {
        margin-bottom: 28px;
    }

    p {
        font-size: 18px;
        line-height: 35px;
        margin-bottom: 45px;

        @media (max-width: 567px) {
            font-size: 16px;
            line-height: 28px;
        }
    }

    .link-box img {
        margin-left: 14px;
    }
}

.articles {
    max-width: 1042px;
    @include margin-auto;

    .section-head {
        margin-bottom: 54px;
    }

    &-cards {}

    &-card {
        display: flex;
        align-items: center;
        padding: 32px 0px;
        background-color: $color-gray;

        @media (max-width: 991px) {
            flex-wrap: wrap;
        }

        &:not(:last-child) {
            margin-bottom: 24px;
        }

        &-date {
            flex: 0 0 95px;
            font-size: 20px;
            text-align: center;
            border-right: .5px solid $color-sub;
            text-transform: uppercase;

            @media (max-width: 767px) {
                flex: 0 0 70px;
            }

            span {
                font-size: 45px;
                display: block;
                margin-top: 12px;

                @media (max-width: 767px) {
                    font-size: 28px;
                }
            }
        }

        &-cont {
            flex: auto;
            padding-left: 50px;

            @media (max-width: 991px) {
                flex: calc(100% - 290px);
            }

            @media (max-width: 767px) {
                padding-left: 25px;
                padding-right: 10px;
            }

            @media (max-width: 567px) {
                padding-left: 15px;
            }

            h3 {
                font-size: 24px;
                font-weight: 500;
                margin-bottom: 8px;

                @media (max-width: 767px) {
                    font-size: 22px;
                }

                @media (max-width: 567px) {
                    font-size: 16px;
                }
            }

            p {
                font-size: 14px;
                line-height: 24px;

                @media (max-width: 767px) {
                    font-size: 14px;
                }

                @media (max-width: 567px) {
                    font-size: 12px;
                }
            }
        }

        &-link {
            flex: 0 0 290px;
            text-align: center;

            @media (max-width: 991px) {
                flex: 0 0 100%;
                text-align: left;
                margin-top: 20px;
                margin-left: 20px;
            }

            .link-box {
                font-size: 16px;

                @media (max-width: 767px) {
                    font-size: 14px;
                    padding: 12px 18px;
                }

                img {
                    @media (max-width: 767px) {
                        width: 20px;
                    }
                }
            }
        }
    }
}

.ctanewsletter {
    &-box {
        .heading {

            &,
            .font-weight-bold {
                color: #fff;
            }

            &-bar-alt {
                @include margin-auto;
            }
        }
    }

    .section-head {
        margin-bottom: 77px;

        @media (max-width: 567px) {
            margin-bottom: 30px;
        }
    }

    &-form {
        position: relative;
        width: 100%;
        height: 70px;

        input {
            height: 100%;
        }

        input:not([type="submit"]) {
            padding: 22px 25px;
            padding-right: 170px;
            width: 100%;
            font-size: 18px;
            border: 2px solid #fff;
            background-color: #fff;
        }

        input[type="submit"] {
            font-size: 20px;
            padding: 20px 40px;
            position: absolute;
            bottom: 0;
            right: 0;
            height: 100%;
            min-width: 1px;
            font-weight: 700;
            color: #fff;
            background-color: #99BABD;

            &:hover {

                background-color: $color-main;
            }
        }

        @media (max-width: 991px) {
            input[type="submit"] {
                font-size: 16px;
                padding: 15px 20px;
            }
        }
    }
}

.dropline {
    &-form {
        max-width: 867px;
        @include margin-auto;

        [class^="col-"] {
            margin-bottom: 20px;
        }

        label {
            font-size: 20px;
            text-transform: uppercase;
            margin-bottom: 12.5px;
        }

        button {
            font-size: 18px;
            height: 60px;
            margin-top: 55px;
        }

        input,
        textarea {
            font-size: 20px;
            padding: 18px;
            border: 1px solid #000;
        }

        input {
            height: 64px;
        }

        textarea {
            min-height: 202px;
        }
    }
}

.map {
    width: 100%;
    height: 491px;

    iframe {
        @include imgfit;
    }

    @media (max-width: 991px) {
        height: 700px;
    }

    @media (max-width: 567px) {
        // height: 300px;
    }

    &-overlay {
        @include absoldefault;
        background-color: rgba(#C7C7C7, .42);

        &--marker {
            @include absolcenter;
            width: 170px;
            text-align: center;
            padding-bottom: 50px;
            cursor: pointer;

            img {
                width: 50px;
                transition: all .3s ease-out;
            }
        }

        &--dialogbox {
            @include absolcenter;
            left: 65%;
            width: 468px;
            padding: 24px 25px 24px 107px;
            padding-left: 107px;
            background-color: #fff;
            opacity: 1;
            transition: all .3s ease-out;

            @media (max-width: 1600px) {
                left: 70%;
            }

            @media (max-width: 1300px) {
                left: 75%;
            }

            @media (max-width: 1200px) {
                left: 77%;
            }

            @media (max-width: 991px) {
                top: auto;
                bottom: 5%;
                left: 50%;
                transform: translateX(-50%);
            }

            @media (max-width: 567px) {
                width: 320px;
            }

            &::before {
                content: "";
                @include absoltopcenter;
                right: 100%;
                border: 35px solid transparent;
                border-right-color: #fff;

                @media (max-width: 991px) {
                    top: auto;
                    bottom: calc(100% - 1px);
                    left: 50%;
                    transform: translateX(-50%);
                    border-right-color: transparent;
                    border-bottom-color: #fff;
                }
            }

            &-hero {
                @include absoltopcenter;
                left: 35px;
                width: 49px;
                height: 79px;
            }

            h3 {
                color: $color-main;
                font-size: 20px;
                font-weight: 700;
                margin-bottom: 14px;
                text-decoration: underline;

                @media (max-width: 567px) {
                    font-size: 16px;
                }
            }

            p {
                position: relative;
                font-size: 17px;
                display: block;
                margin-bottom: 20px;
                padding-left: 23px;

                @media (max-width: 567px) {
                    font-size: 14px;
                }

                img {
                    position: absolute;
                    top: 7px;
                    left: 0;
                    width: 12px;
                }

                span {
                    margin-bottom: 0;
                }
            }
        }
    }

    // &:hover &-overlay--dialogbox {
    //     left: 65%;
    //     opacity: 1;
    // }
}