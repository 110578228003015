.anim-delay750 {
  animation-delay: .75s;
}

.anim-delay1000 {
  animation-delay: 1s;
}

.anim-delay1250 {
  animation-delay: 1.25s;
}

.anim-delay1500 {
  animation-delay: 1.5s;
}

.anim-delay1750 {
  animation-delay: 1.75s;
}

.anim-delay2000 {
  animation-delay: 2s;
}


@-webkit-keyframes carouimgzoom {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}


@keyframes carouimgzoom {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sonar {
  from {
    opacity: 1;
    width: 0;
    height: 0;
  }

  to {
    opacity: 0;
    width: 500px;
    height: 500px;
  }
}

@keyframes wave {
  0% {
    margin-left: 0;

  }

  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {

  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }

  50% {
    transform: translate3d(0, 5px, 0);
  }
}

@-webkit-keyframes zoomInZoomOut {
  50% {
    transform: scale(1.5) translate(-50%, -50%);
  }

  100% {
    transform: scale(1.1) translate(-50%, -50%);
  }
}

@keyframes zoomInZoomOut {
  50% {
    transform: scale(1.5) translate(-50%, -50%);
  }

  100% {
    transform: scale(1.1) translate(-50%, -50%);
  }
}


@keyframes blow {
  20% {}

  30% {
    opacity: 0;
  }

  40% {
    width: 200%;
    height: 200%;
  }

  100% {
    width: 20%;
    height: 20%;
    opacity: 1;
  }
}


@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.7);
    opacity: 0;
  }
}


/* Fame Animation Keyframes */
@-webkit-keyframes pulse-front {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
  }

  25% {
    opacity: 1;
    -webkit-transform: scale(1.3, 1.3);
    -ms-transform: scale(1.3, 1.3);
    transform: scale(1.3, 1.3);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1.7, 1.7);
    -ms-transform: scale(1.7, 1.7);
    transform: scale(1.7, 1.7);
  }
}

@keyframes pulse-front {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
  }

  25% {
    opacity: 1;
    -webkit-transform: scale(1.3, 1.3);
    -ms-transform: scale(1.3, 1.3);
    transform: scale(1.3, 1.3);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1.7, 1.7);
    -ms-transform: scale(1.7, 1.7);
    transform: scale(1.7, 1.7);
  }
}

@-webkit-keyframes shake-break {
  from {
    -webkit-transform: rotate(45deg);
  }

  to {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(35deg);
  }

}

@-webkit-keyframes rotating

/* Safari and Chrome */
  {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }

  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-20px);
  }

  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}

@-webkit-keyframes run {
  0% {
    top: -50%;
  }

  100% {
    top: 110%;
  }
}

@keyframes run {
  0% {
    top: -50%;
  }

  100% {
    top: 110%;
  }
}

@keyframes eltdfDrawDecor {
  from {
    stroke-dashoffset: 57;
    stroke-dasharray: 57
  }

  to {
    stroke-dashoffset: 0;
    stroke-dasharray: 57
  }
}

@-webkit-keyframes bounce {

  20%,
  53%,
  80%,
  from,
  to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(.755, .050, .855, .060);
    animation-timing-function: cubic-bezier(.755, .050, .855, .060);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0)
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(.755, .050, .855, .060);
    animation-timing-function: cubic-bezier(.755, .050, .855, .060);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0)
  }

  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0)
  }
}

@keyframes bounce {

  20%,
  53%,
  80%,
  from,
  to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(.755, .050, .855, .060);
    animation-timing-function: cubic-bezier(.755, .050, .855, .060);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0)
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(.755, .050, .855, .060);
    animation-timing-function: cubic-bezier(.755, .050, .855, .060);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0)
  }

  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0)
  }
}