.pageheader {
  z-index: 1;
  position: relative;
  width: 100%;
  height: 588px;
  text-align: center;

  @media (max-width: 991px) {
    height: 400px;
  }

  &::after {
    z-index: 1;
    content: "";
    position: absolute;
    @include absoldefault;
    background-color: rgba(#707070, 0.24);
    mix-blend-mode: multiply;
  }

  &-cont {
    z-index: 2;
    position: absolute;
    bottom: 145px;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 991px) {
      bottom: 5%;
    }

    .heading {
      color: #fff;
      font-size: 50px;
      line-height: 50px;
      @include unselect;

      @media (max-width: 991px) {
        font-size: 36px;
        line-height: 45px;
      }

      strong {
        display: block;
      }
    }

    .title-bar {
      @include margin-auto;
    }
  }

}