.section {
    &-banner-about {
        background-color: rgba($color-sub, .14);

        @media (min-width: 992px) {
            padding: 155px 0 70px;
        }
    }

    &-team {
        @media (min-width: 1200px) {
            padding: 120px 0 190px;
        }
    }
}

.banner-about {
    &-hero {
        position: relative;
        max-width: 1022px;
        height: 577px;
        margin: 105px auto 97px;

        @media (max-width: 991px) {
            height: 380px;
        }

        @media (max-width: 767px) {
            height: 280px;
        }

        @media (max-width: 567px) {
            height: 200px;
        }

        &-item {
            position: absolute;
            width: 38%;
            height: 55.5%;
            transition: all .3s ease-out;

            &:nth-child(1) {
                top: 0;
                left: 10.6%;
            }

            &:nth-child(2) {
                bottom: 0;
                right: 0;
                width: 62.65%;
                height: 91.52%;
            }

            &:nth-child(3) {
                bottom: 0;
                left: 0;
            }

            &-shape {
                width: 100%;
                height: 100%;
                mix-blend-mode: multiply;
            }

            &-title {
                color: #fff;
                @include absolcenter;
                text-align: center;
                font-family: $font-title;
                font-size: 40px;
                font-weight: 300;

                @media (max-width: 991px) {
                    font-size: 26px;
                }

                @media (max-width: 767px) {
                    font-size: 20px;
                }

                @media (max-width: 567px) {
                    font-size: 17px;
                }
            }
        }

        &:hover &-item:nth-child(1) {
            top: -11%;
            left: 6%;
        }

        &:hover &-item:nth-child(2) {
            bottom: -12%;
            right: -12.75%;
        }

        &:hover &-item:nth-child(3) {
            bottom: -15.5%;
            left: -5%;
        }
    }

    &-cont {
        p {
            font-size: 20px;
            line-height: 30px;
        }
    }
}

.team {
    &-shape {

        &-1,
        &-2 {
            position: absolute;
        }

        &-1 {
            width: 25.65%;
            top: 25%;
            left: 0;
            transform: translate(-50%, -50%);
        }

        &-2 {
            width: 39.25%;
            top: 35%;
            right: 0;
            transform: translate(50%, -50%);
        }
    }

    .row {
        @media (min-width: 1601px) {
            margin: 0 -80px;
        }

        @media (min-width:991px) and (max-width: 1400px) {
            margin: 0 -30px;
        }
    }

    [class^="col-"] {
        margin-bottom: 15px;

        @media (min-width: 1601px) {
            padding: 0 80px;

            &:nth-child(-n+3) {
                margin-bottom: 145px;
            }
        }

        @media (min-width:991px) and (max-width: 1400px) {
            padding: 0 30px;

            &:nth-child(-n+4) {
                margin-bottom: 70px;
            }
        }
    }

    &-card {
        &-cont {
            display: flex;
        }

        &-title {}

        &-link {
            margin-left: auto;
            text-align: right;
        }

        &-social {
            margin-bottom: 6px;

            @media (max-width: 567px) {
                margin-bottom: 2px;
            }

            a {
                display: inline-block;

                &:not(:first-child) {
                    margin-left: 6px;
                }
            }

            .fab {
                font-size: 32px;
                color: #303A86;

                @media (max-width: 567px) {
                    font-size: 24px;
                }
            }
        }

        &-readmore {
            a {
                font-size: 14px;
                font-weight: 700;

                &:hover {
                    color: #0089FF;
                    text-decoration: underline;
                }

                img {
                    width: 13.5px;
                    margin-left: 18px;
                }
            }
        }

        &-profile {
            height: 533px;
            overflow: hidden;
            margin-bottom: 6px;

            @media (max-width: 1200px) and (min-width: 768px) {
                height: 450px;
            }

            @media (max-width: 767px) and (min-width: 568px) {
                height: 533px;
            }

            @media (max-width: 567px) {
                height: 400px;
            }

            img {
                @include imgfit;
                transition: all .3s ease-out;
            }
        }

        &:hover &-profile img {
            transform: scale(1.1);
        }

        h3 {
            font-size: 22px;
            font-weight: 600;

            @media (max-width: 567px) {
                font-size: 20px;
                margin-bottom: 1px;
            }
        }

        p {
            font-size: 18px;
            font-weight: 300;

            @media (max-width: 567px) {
                font-size: 17px;
            }
        }
    }
}

.community {
    .section-head {
        .heading {
            margin-bottom: 20px;

            @media (min-width: 992px) {
                font-size: 40px;
            }
        }

        p {
            max-width: 540px;
            font-size: 20px;
            line-height: 32px;
        }
    }

    .row {
        align-items: center;
        justify-content: center;
    }

    [class^="col-"] {
        text-align: center;
        margin-bottom: 15px;

        @media (min-width: 992px) {
            flex: 0 0 20%;
            max-width: 20%;
        }

        img {
            max-width: 90%;
        }
    }
}