.formfield {
    margin-bottom: 2rem;

    label {
        font-size: 1.75rem;
        font-weight: 500;
    }

    small {
        color: $color-main;
        font-size: 1.5rem;
        display: block;
        margin-bottom: .2rem;
    }

    &[class*="col-"] {
        padding-top: 0;
        padding-bottom: 0;
    }

    input+input {
        margin-top: 1rem;
    }

    &>input:not([type="submit"]),
    &>select:not([size]):not([multiple]),
    &>textarea {
        font-size: 1.4rem;
        min-width: 120px;
        border-radius: 0;
        background-color: #fff;
    }

    &>select:not([size]):not([multiple]) {}

    &>input:not([type="submit"]),
    &>select:not([size]):not([multiple]) {
        width: 100%;
        height: 4.5rem;
    }

    &>input:not([type="submit"]):focus {
        border: 0;
        border-bottom: 2px solid #222;
        box-shadow: none;
    }

    &>input[type="checkbox"] {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        min-width: 1px;
        transform: translateY(2px);
        margin-right: .5rem;
    }

    &>textarea {
        width: 100%;
        max-width: 100%;
        min-height: 10rem;

        &:focus {

            border: 1px solid $color-main;
            box-shadow: none;
            background-color: transparent;
        }
    }

    &-submit>input {
        width: auto;
        display: inline-block;
        padding: .8rem 1.7rem;
        font-size: 1.4rem;
        font-weight: 500;
        color: #fff;
        background-color: $color-main;
        border: 0;
        cursor: pointer;
        text-transform: uppercase;

        &:hover {
            background-color: $color-main;
        }
    }
}

.form-check {
    padding-left: 0;
    margin-bottom: 1rem;
    // display: flex;

    &[class*="col-"] {
        padding-top: 1rem;
        padding-bottom: 1rem;
        margin-bottom: 0;
    }

    input[type="radio"],
    input[type="checkbox"] {
        width: auto;
        display: inline-block;
        margin-right: .5rem;

        ~label {
            margin-bottom: 0;
            cursor: pointer;
            @include unselect;
        }
    }
}

.customradio {
    input[type="radio"] {
        display: none;
    }

    label {
        position: relative;

        &::before {
            content: "";
            display: inline-block;
            width: 1.5rem;
            height: 1.5rem;
            background-color: #fff;
            border: .4rem solid #fff;
            border-radius: 50%;
            margin-right: 1rem;
        }
    }

    input:checked~label::before {
        border-color: $color-main
    }
}

.customcheckbox {
    input[type="checkbox"] {
        display: none;
    }

    label {
        position: relative;

        &::before {
            content: "";
            display: inline-block;
            width: 1.5rem;
            height: 1.5rem;
            color: $color-main;
            background-color: #fff;
            margin-right: 1rem;
        }

        &::after {
            opacity: 0;
            content: "\f00c";
            font-family: $font-awesome;
            font-size: 1.6rem;
            font-weight: 700;
            color: $color-main;
            position: absolute;
            top: 0;
            left: 0;
            transform: scale(2);
            transition: .3s all ease;
        }
    }

    input:checked~label::after {
        opacity: 1;
        transform: scale(1);
    }
}

.formfield.formfield-special {
    position: relative;
    margin: 1rem 0 2rem;

    label {
        font-size: 1.5rem;
        position: absolute;
        top: 5px;
        left: 10px;
        margin-bottom: 0;
        transition: all .4s ease;
    }


    &>input:not([type="submit"]),
    &>select:not([size]):not([multiple]),
    &>textarea {
        font-size: 1.4rem;
        min-width: 120px;
        padding-top: 2rem;
        height: 5.5rem;
        border: 1px solid rgba(#111, 0.5);
        border-radius: 4px;
        background-color: #fff;
    }

    &>input:not([type="submit"]):focus,
    &>select:not([size]):not([multiple]):focus,
    &>textarea:focus {
        // border-color: $color-dark;
    }

    input[type="submit"] {
        height: 3rem;
    }

    &.focussed {
        label {
            font-size: 1.2rem;
            top: -5px;
            left: 10px;
            color: #fff;
            background-color: $color-main;
            padding: 5px 2px;
        }

        &>input:not([type="submit"]),
        &>select:not([size]):not([multiple]),
        &>textarea {
            // border-color: $color-dark;
        }
    }
}