.serv {
    &-shape {

        &-1,
        &-2 {
            z-index: -1;
            position: absolute;
            top: 50%;
        }

        &-1 {
            left: 0;
            transform: translate(-50%, -50%);
            width: 39%;
        }

        &-2 {
            top: 65%;
            left: auto;
            right: 0;
            transform: translate(50%, -50%);
            width: 13%;
        }
    }

    [class^="col-"] {
        @media (min-width: 1201px) {
            &:first-child {
                max-width: 350px;
            }

            &:last-child {
                flex: auto;
                max-width: calc(100% - 350px);
            }
        }

        @media (max-width: 1200px) {
            &:first-child {
                margin-bottom: 30px;
            }
        }
    }

    &-side {
        @media (min-width: 1201px) {
            max-width: 260px;
        }

        .heading {
            margin-bottom: 15px;
        }

        p {
            font-size: 18px;
            line-height: 30px;

            @media (max-width: 767px) {
                font-size: 18px;
            }
        }
    }

    .expertise-card {
        display: flex;

        @media (max-width: 991px) {
            flex-wrap: wrap;
        }

        &:not(:last-child) {
            margin-bottom: 115px;

            @media (max-width: 991px) {
                margin-bottom: 50px;
            }
        }

        &-img {
            width: 423px;
            max-width: 100%;
            flex: 0 0 423px;
            height: 407px;

            @media (max-width: 767px) {
                font-size: 18px;
                height: 350px;
            }

            @media (max-width: 567px) {
                height: 280px;
            }
        }

        &-title {
            font-size: 110px;
            letter-spacing: -16.5px;
            line-height: 90px;
            width: 100%;

            @media (max-width: 567px) {
                font-size: 80px;
                letter-spacing: -12px;
            }
        }

        &-cont {
            padding: 0 0 0 35px;
            flex: auto;
            border: 0;
            text-align: left;

            @media (max-width: 991px) {
                padding: 30px 0 0 0;
            }

            h3 {
                font-size: 20px;
                font-weight: 700;
                margin-bottom: 25px;

                @media (max-width: 991px) {
                    margin-bottom: 15px;
                }
            }

            p {
                font-size: 16px;
                line-height: 28px;
                font-weight: 400;
                margin-bottom: 35px;

                @media (max-width: 991px) {
                    margin-bottom: 15px;
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
}