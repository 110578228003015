.link-box {
    color: #fff;
    background-color: $color-main;

    &,
    &-white,
    &-alt,
    &-alt-white,
    &-border {
        display: inline-block;
        padding: 20px 32px;
        font-size: 17px;
        font-weight: 500;
        text-align: center;
        text-transform: capitalize;

        img {
            margin-left: 16px;
        }
    }

    &-alt {
        border: 1px solid #fff;

        &:hover {
            color: #fff !important;
            border-color: $color-black;
            background-color: $color-black;
        }
    }

    &-white {
        color: $color-main;
        background-color: #fff;
    }

    &-border {
        color: $color-main;
        background-color: #fff;
        border: 1px solid $color-main;
    }

    &:hover {
        color: #fff;
        background-color: $color-brown;
    }
}